import React, { useContext, useState, useRef, useEffect, useCallback } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { makeAutoObservable } from 'mobx'
import { Observer, observer, useLocalObservable } from 'mobx-react-lite'
import clsx from 'clsx'
import localStorage from 'store2'
import { useQueryParam, StringParam } from 'use-query-params'
import { useEvent, usePresenceChannel, usePusher } from '@harelpls/use-pusher'
import { useTime } from 'react-timer-hook'
import { add, getUnixTime, fromUnixTime } from 'date-fns'
import { useSnackbar } from 'notistack'

import {
  Button,
  ButtonBase,
  IconButton,
  InputAdornment,
  Link,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Typography,
  TextField,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { makeStyles, createStyles } from '@material-ui/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone'
import WatchLaterIcon from '@material-ui/icons/WatchLater'

import defaultGroups from '../../groups'
import { ISlot } from 'AppDB'
import { AppStoreCtx, Page, useHub } from 'components'
import { useManageService, useFirestore } from 'hooks'

import debug from 'debug'
const log = debug('@ikew:views:Manage')

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      background: '#30313c',
    },
    connectedProgress: {
      color: 'white',
      alignSelf: 'center',
      margin: '0 auto',
    },

    alerts: {
      margin: theme.spacing(0, 1, 0, 1),
    },
    globalAlert: {},

    main: {
      flex: '1 0 auto',
      display: 'flex',
      padding: theme.spacing(1),
      height: '100vh',
    },

    // Sidebar
    sidebar: {
      marginRight: theme.spacing(0.5),
    },
    sidebarBtn: (group?: any) => ({
      paddingBottom: 0,
      '& button': {
        width: '100%',
      },
      '& h3': {
        backgroundColor: group.color,
        padding: theme.spacing(3, 1),
        marginBottom: theme.spacing(1),
        display: 'block',
        color: 'white',
        textAlign: 'center',
        borderRadius: 4,
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.3)',
      },
      '& h4': {
        color: '#dff',
      },
    }),
    editWaitTime: {
      '& h3': {
        backgroundColor: '#51545e',
      },
    },
    connected: {
      color: 'white',
    },

    // Groups
    gridItem: {
      display: 'flex',
    },
    stageWrap: {
      display: 'flex',
      flex: 1,
    },
    stage: {
      position: 'relative',
      padding: theme.spacing(1),
      margin: theme.spacing(0, 0.5),
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      '& h3': {
        textAlign: 'center',
        margin: theme.spacing(2, 0),
      },
    },
    stageInProgress: {
      '& h3': {
        color: '#1773aa',
      },
    },
    stageReady: {
      '& h3': {
        color: '#3fa43f',
      },
    },
    stageActions: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(0.5),
    },

    slots: {
      display: 'flex',
      flexWrap: 'wrap',
      overflowY: 'scroll',
      alignItems: 'start',
      '& h4': {
        width: '100%',
        textAlign: 'center',
      },
    },
    slot: {
      flex: '0 0 25%',
      padding: '4px',
      position: 'relative',
      '@media (min-width: 1280px) and (orientation: landscape)': {
        flex: '0 0 20%',
      },
    },
    slotPending: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
    },
    slotError: {
      position: 'absolute',
      top: theme.spacing(0.5),
      right: theme.spacing(0.5),
    },
    slotColor: (group?: any) => ({
      backgroundColor: group.color,
    }),
    readyExpiredContext: (props?: any) => ({
      borderBottom: `2px solid ${props.color}`,
    }),
    contextBtn: {
      padding: theme.spacing(3, 3),
    },
    slotBtn: {
      padding: theme.spacing(2.5, 1),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    slotLabel: {
      fontSize: '2.5em',
      textAlign: 'center',
      fontWeight: 500,
      color: 'white',
      textShadow: '1px 1px 1px rgba(0, 0, 0, 0.3)',
    },

    inputWrap: {
      position: 'absolute',
      top: theme.spacing(1),
      left: 0,
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
    },
    slotInput: {
      zIndex: 9999,
    },
    waitTimeInput: {
      zIndex: 9999,
      '& > div': {
        margin: theme.spacing(1),
      },
    },

    dev: {
      background: '#333',
      padding: theme.spacing(2),
      color: 'white',
    },
  }),
)

const Manage = () => {
  const css = useStyles()

  const hub = useHub()
  const history = useHistory()
  const appStore: any = useContext(AppStoreCtx)
  const state: any = useLocalObservable(() => ({
    storeId: appStore.storeId,
    selectedGroup: null,

    editWaitTime: false,

    changes: 0,
    update() {
      state.changes += 1
    },

    connected: false,
    connectedDisplays: 0,

    slots: [],

    waitTime: null,

    groups: {},
  }))

  // handle setting store id via query string
  const [storeParam] = useQueryParam('store', StringParam)
  if (storeParam) {
    localStorage.set('storeId', storeParam, true)
    // refresh without the query string
    window.location.href = window.location.pathname
  }

  useEffect(() => {
    async function getStore() {
      if (!state.storeId) {
        return
      }
      try {
        const response = await hub.getStore()
        const { data: store } = response
        state.store = store
        const newGroups: any = {}

        if (store.groups) {
          const storeGroups = buildGroupsStore(store.groups)
          for (const [key, group] of Object.entries(storeGroups)) {
            newGroups[key] = group
          }
        } else {
          for (const [key, group] of Object.entries(defaultGroups)) {
            newGroups[key] = group
          }
        }
        state.groups = newGroups
      } catch (err) {
        const { response } = err
        if (!response) {
          state.error = true
        }
        if (response && response.status === 404) {
          history.replace('/')
        }
      }
    }
    getStore()
  }, [history, hub, state, state.error, state.store, state.storeId, state.waitTime])

  if (!state.storeId) {
    return <Redirect to="/set-store" />
  }

  return (
    <Page className={css.root} title={`Manage ${state.storeId}`}>
      <ConnectedDisplays state={state} />
      <Observer>
        {() => (
          <>
            {state.waitTime && <WaitTime state={state} />}

            <Slots state={state} />

            <div className={css.alerts}>
              {!state.connected && (
                <Alert severity="error" className={css.globalAlert}>
                  <Typography variant="h5">Offline</Typography>
                </Alert>
              )}

              {state.connected && state.connectedDisplays < 1 && (
                <Alert severity="error" className={css.globalAlert}>
                  <Typography variant="h5">No displays connected!</Typography>
                </Alert>
              )}
            </div>

            <div className={css.main}>
              <Sidebar state={state} />

              <div className={css.stageWrap}>
                <Paper className={clsx(css.stage, css.stageInProgress)}>
                  <PreparingActions state={state} />
                  <Typography variant="h3">Preparing</Typography>
                  <Preparing state={state} />
                </Paper>

                <Paper className={clsx(css.stage, css.stageReady)}>
                  <ReadyActions state={state} />
                  <Typography variant="h3">Ready</Typography>
                  <Ready state={state} />
                </Paper>
              </div>

              {state.selectedGroup && <SlotInput state={state} />}
              {state.editWaitTime && <WaitTimeInput state={state} />}
            </div>

            <div className={css.dev}>
              {process.env.NODE_ENV === 'development' && (
                <pre>{JSON.stringify(state, null, 2)}</pre>
              )}
            </div>
          </>
        )}
      </Observer>
    </Page>
  )
}

const Slots = observer((props: any) => {
  const { state } = props
  const { storeId } = state

  const { db } = useFirestore()

  useEffect(() => {
    if (!db) {
      return
    }

    db.collection(storeId).onSnapshot((querySnapshot: any) => {
      const slots: UISlot[] = []
      querySnapshot.forEach((doc: any) => {
        const data = doc.data()
        if (doc.id === '_state') {
          state.waitTime = data
          return
        }
        const uiSlot = new UISlot({
          ...data,
          id: doc.id,
          ready: data.ready ? 1 : 0,
          cleared: 0,
        })
        slots.push(uiSlot)
      })
      state.slots = [...slots]
    })
    // eslint-disable-next-line
  }, [db, storeId])

  return null
})

const ConnectedDisplays = (props: any) => {
  const { state } = props
  const { storeId } = state

  const hub = useHub()
  const { enqueueSnackbar } = useSnackbar()

  const { channel, members }: any = usePresenceChannel(`presence-${storeId}`)

  const { client } = usePusher()

  useEffect(() => {
    if (!client) {
      log('No pusher client')
      return
    }
    client.connection.bind('state_change', (states: any) => {
      const { current } = states
      state.connected = current === 'connected'
    })
  })

  useEffect(() => {
    let total = 0
    Object.keys(members).forEach((key: string) => {
      if (key.startsWith('display_')) {
        total += 1
      }
    })
    if (total > 0) {
      state.connectedDisplays = total
    }
  }, [state, members])

  useEvent(channel, 'pusher:member_added', () => {
    log('pusher:member_added')
    state.connectedDisplays += 1
    const slots = state.slots.map((uis: UISlot) => uis.slot)
    hub.trigger('sync', { slots })

    const snackbarOpts: any = { variant: 'info', autoHideDuration: 2000 }
    enqueueSnackbar(`Display #${state.connectedDisplays} connected`, snackbarOpts)
  })

  useEvent(channel, 'pusher:member_removed', () => {
    log('pusher:member_removed')
    state.connectedDisplays -= 1

    const snackbarOpts: any = { variant: 'warning', autoHideDuration: 2000 }
    enqueueSnackbar(`Display #${state.connectedDisplays + 1} disconnected`, snackbarOpts)
  })

  return null
}

const Sidebar = (props: any) => {
  const { state } = props
  const { groups } = state
  const css = useStyles()
  const hub = useHub()
  const onSetWaitTime = () => {
    state.editWaitTime = true
  }
  const onTriggerRefresh = () => {
    hub.trigger('refresh')
  }
  const onSync = () => {
    const slots = state.slots.map((uis: UISlot) => uis.slot)
    hub.trigger('sync', { slots })
  }
  return (
    <div className={css.sidebar}>
      {Object.entries(groups).map(([key, group]) => (
        <GroupBtn key={key} group={group} state={state} />
      ))}

      <div className={clsx(css.sidebarBtn, css.editWaitTime)}>
        <Link component="button" underline="none" onClick={onSetWaitTime}>
          <Typography variant="h3">Wait Time</Typography>
        </Link>
      </div>

      {state.connectedDisplays > 0 && (
        <Link component="button" underline="none" onClick={onTriggerRefresh}>
          <Typography className={css.connected}>
            {state.connectedDisplays} Connected{' '}
            {state.connectedDisplays > 1 ? 'Displays' : 'Display'}
          </Typography>
        </Link>
      )}

      {process.env.NODE_ENV === 'development' && (
        <div className={clsx(css.sidebarBtn)}>
          <Link component="button" underline="none" onClick={onSync}>
            <Typography variant="h3">Sync</Typography>
          </Link>
        </div>
      )}
    </div>
  )
}

const GroupBtn = observer((props: any) => {
  const { group, state } = props
  const css: any = useStyles(group)

  const onClickHeader = () => {
    state.selectedGroup = group
  }

  return (
    <div className={clsx(css.sidebarBtn)}>
      <Link component="button" underline="none" onClick={onClickHeader}>
        <Typography variant="h3">{group.name}</Typography>
      </Link>
    </div>
  )
})

const Preparing = observer((props: any) => {
  const { state } = props
  const { groups } = state
  const css = useStyles()
  const manageSvc = useManageService()

  const onClick = async (uis: UISlot) => {
    if (!uis.slot.id) {
      return
    }
    uis.slot.state = 2
    uis.slot.readyDate = getUnixTime(new Date())
    manageSvc.inProgressToReady(uis.slot).catch(() => {
      uis.isHidden = false
      uis.error = true
    })
  }

  const preparing = state.slots.filter((o: UISlot) => o.preparing)
  const grouped = groupSlots(groups, preparing)

  return (
    <div className={css.slots}>
      {preparing && preparing.length > 0 ? (
        grouped.map((uis: UISlot) => (
          <Slot
            key={uis.slot.id || uis.slot.created}
            slot={uis}
            onClick={onClick}
            groups={groups}
          />
        ))
      ) : (
        <Typography variant="h4">Nothing being prepared</Typography>
      )}
    </div>
  )
})

const PreparingActions = (props: any) => {
  const { state } = props
  const css = useStyles()
  const hub = useHub()
  const manageSvc = useManageService()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const removeAll = async () => {
    hub.trigger('removePreparing')
    await manageSvc.removePreparing()
    state.update()
    handleClose()
  }

  return (
    <div className={css.stageActions}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem key="noop" onClick={removeAll}>
          Remove All
        </MenuItem>
      </Menu>
    </div>
  )
}

const Ready = observer((props: any) => {
  const { state } = props
  const { groups } = state
  const { slots } = state
  const css = useStyles()
  const manageSvc = useManageService()

  const onClick = async (uis: UISlot) => {
    const { slot } = uis
    if (!slot.id) {
      return
    }
    uis.isHidden = true
    manageSvc
      .clear(slot)
      .then(() => {
        state.slots = state.slots.filter((o: UISlot) => o.slot.id !== slot.id)
      })
      .catch(() => {
        uis.isHidden = false
        uis.error = true
      })
  }

  const ready = slots.filter((uis: UISlot) => uis.ready)
  const grouped = groupSlots(groups, ready)

  return (
    <div className={css.slots}>
      {slots && slots.length > 0 ? (
        grouped.map((uis: UISlot) => (
          <Slot
            key={uis.slot.id || uis.slot.created}
            slot={uis}
            onClick={onClick}
            groups={groups}
          />
        ))
      ) : (
        <Typography variant="h4">Nothing Ready</Typography>
      )}
    </div>
  )
})

const ReadyActions = (props: any) => {
  const { state } = props
  const css = useStyles()
  const manageSvc = useManageService()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const clearSlots = async () => {
    manageSvc.clearReady().then(() => {
      state.update()
    })
    handleClose()
  }

  return (
    <div className={css.stageActions}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem key="noop" onClick={clearSlots}>
          Clear All
        </MenuItem>
      </Menu>
    </div>
  )
}

export const Slot = observer((props: any) => {
  const { slot: uiSlot, onClick, expires = 180, groups, ...rest } = props
  const { slot, pending, error } = uiSlot

  const css: any = useStyles(groups[slot.group])
  useTime()

  if (!uiSlot || uiSlot.isHidden) {
    return null
  }

  let expired = false
  if (!!uiSlot.slot.readyDate) {
    const readyDate = fromUnixTime(uiSlot.slot.readyDate)
    const readyExpiry = add(readyDate, { seconds: expires })
    const now = new Date()
    if (now > readyExpiry) {
      expired = true
    }
  }

  const style = {
    [css.slotColor]: true,
  }

  const handleClick = (event: any) => {
    onClick(uiSlot)
  }

  return (
    <div className={css.slot}>
      <Paper {...rest} className={clsx(style)} elevation={0}>
        {pending && <LinearProgress variant="indeterminate" className={css.slotPending} />}
        <ButtonBase className={css.slotBtn} onClick={handleClick}>
          <Typography className={css.slotLabel}>
            {slot.label}
            {error && <ErrorTwoToneIcon className={css.slotError} />}
            {expired && <WatchLaterIcon className={css.slotError} />}
          </Typography>
        </ButtonBase>
      </Paper>
    </div>
  )
})

const SlotInput = observer((props: any) => {
  const { state } = props
  const { slots } = state
  const css: any = useStyles()
  const slotInput: any = useRef()
  const manageSvc = useManageService()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = async (ev: any) => {
    ev.persist() // to allow shiftKey detection
    if (ev.key === 'Enter') {
      const label = slotInput.current.value
      const group = state.selectedGroup
      if (!label || !group) {
        return
      }

      const slot = {
        id: `${group.id}_${label}__pending`,
        label,
        group: group.id,
      }
      const n = slots.push(new UISlot(slot, true))
      slotInput.current.value = ''
      manageSvc
        .addInProgress({ label, group: group.id })
        .then((slot: ISlot) => {
          slots[n - 1] = new UISlot(slot, false)
        })
        .catch(() => {
          slots.splice(n - 1, 1)
          const snackbarOpts: any = {
            variant: 'error',
            autoHideDuration: 1500,
          }
          enqueueSnackbar(`Error adding ${group.name}: ${label}`, snackbarOpts)
        })

      // sequence id
      const { sequenceId } = state.selectedGroup
      if (sequenceId) {
        const num = parseInt(label, 10)
        if (num) {
          state.selectedGroup.nextId = num + 1
        }
      }

      if (!ev.shiftKey) {
        state.selectedGroup = null
      }
      ev.preventDefault()
    }
  }
  const onFocus = (event: any) => {
    event.target.select()
  }
  const startAdornment = state.selectedGroup ? (
    <InputAdornment position="start">{state.selectedGroup.id}</InputAdornment>
  ) : null

  const onCancel = () => {
    state.selectedGroup = null
  }

  // set sequence ids
  const addProps: any = {}
  const { sequenceId, nextId } = state.selectedGroup
  if (sequenceId && !!nextId) {
    addProps.defaultValue = nextId
  }

  return (
    <div className={css.inputWrap}>
      <Paper className={css.slotInput}>
        <TextField
          variant="outlined"
          inputRef={slotInput}
          id="slot-input"
          fullWidth
          onKeyPress={onSubmit}
          onFocus={onFocus}
          onBlur={onCancel}
          InputProps={{
            autoFocus: true,
            type: 'text',
            startAdornment,
          }}
          {...addProps}
        />
      </Paper>
    </div>
  )
})

const WaitTimeInput = observer((props: any) => {
  const { state } = props
  const css: any = useStyles()
  const inputRef: any = useRef()
  const expireRef: any = useRef()
  const manageSvc = useManageService()
  const { closeSnackbar } = useSnackbar()

  const onWaitTimeBlur = () => {
    if (!inputRef.current.value) {
      onCancel()
    }
  }
  const onCancel = () => {
    state.editWaitTime = false
  }
  const onSubmit = async (ev: any) => {
    if (ev.key === 'Enter') {
      const waitTime = parseFloat(inputRef.current.value)
      const minutes = parseFloat(expireRef.current.value)
      if (!waitTime || !minutes) {
        ev.preventDefault()
        onCancel()
      }

      if (state.waitTimeSnackKey) {
        closeSnackbar(state.waitTimeSnackKey)
        state.waitTimeSnackKey = null
      }
      if (state.waitTime) {
        state.waitTime = null
      }

      const expire = getUnixTime(add(new Date(), { minutes }))
      manageSvc.setWaitTime(waitTime, expire).then(() => {
        state.waitTime = {
          waitTime,
          expire,
        }
      })
      ev.preventDefault()
      onCancel()
    }
  }
  const endAdornment = <InputAdornment position="end">mins</InputAdornment>

  return (
    <div className={css.inputWrap}>
      <Paper className={css.waitTimeInput}>
        <TextField
          type="number"
          placeholder="Wait Time"
          variant="outlined"
          inputRef={inputRef}
          id="wait-time-input"
          onBlur={onWaitTimeBlur}
          InputProps={{
            autoFocus: true,
            endAdornment,
          }}
        />

        <TextField
          type="number"
          placeholder="Expires In"
          variant="outlined"
          inputRef={expireRef}
          id="wait-time-expire-input"
          onKeyPress={onSubmit}
          onBlur={onCancel}
          InputProps={{
            endAdornment,
          }}
        />
      </Paper>
    </div>
  )
})

const WaitTime = (props: any) => {
  const { state } = props
  const { seconds } = useTime()
  const manageSvc = useManageService()
  // keep expire value to detect when a change happens while a current timer is running
  const [expire, setExpire] = useState(state.waitTime?.expire)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const snackAction = useCallback(
    (key: any) => (
      <Button
        color="secondary"
        onClick={() => {
          closeSnackbar(key)
          manageSvc.resetWaitTime()
          state.waitTime = null
        }}
      >
        Reset
      </Button>
    ),
    [state, manageSvc, closeSnackbar],
  )

  useEffect(() => {
    if (!state.waitTime) {
      return
    }

    const now = getUnixTime(new Date())

    // prioritize expiry change so the existing comp can be reused when
    if (state.waitTime.expire !== expire) {
      setExpire(state.waitTime.expire)
    } else if (now > state.waitTime.expire) {
      log('Resetting wait time')
      state.waitTime = null
      state.waitTimeSnackKey = null
      closeSnackbar(state.waitTime?.snackbarKey)
      manageSvc.resetWaitTime()
    }

    if (state.waitTime && !state.waitTimeSnackKey) {
      log('Starting wait time timer')
      const snackbarOpts: any = {
        variant: 'info',
        autoHideDuration: 1500,
        persist: true,
        action: snackAction,
      }
      state.waitTimeSnackKey = enqueueSnackbar(<WaitMessage state={state} />, snackbarOpts)
    }
  }, [state, expire, seconds, manageSvc, enqueueSnackbar, closeSnackbar, snackAction])

  return null
}

const WaitMessage = (props: any) => {
  const { state } = props

  // refresh every second
  const { seconds } = useTime()
  useEffect(() => {}, [seconds])

  if (!state.waitTime) {
    return null
  }

  const { waitTime, expire } = state.waitTime
  const waitMins = waitTime > 1 ? 'mins' : 'min'
  const expiryMins = Math.ceil((expire - getUnixTime(new Date())) / 60)
  const minsLabel = expiryMins > 1 ? 'mins' : 'min'
  return <>{`${waitTime} ${waitMins} wait time (${expiryMins} ${minsLabel} left)`}</>
}

// Group slots by "group"
const groupSlots = (groups: any, slots: UISlot[]) => {
  const ixs: { [key: string]: number } = {}
  const grouped: any = []
  Object.keys(groups).forEach((id: string, ix: number) => {
    ixs[id] = ix
    grouped.push([])
  })
  slots.forEach((uis: UISlot) => {
    const groupIx = ixs[uis.slot.group]
    if (!(uis.slot.group in ixs)) {
      log('Invalid group: ', uis.slot.group)
      return
    }
    grouped[groupIx].push(uis)
  })
  let results: UISlot[] = [].concat(...grouped)
  return results
}

export class UISlot {
  slot: any
  pending: boolean
  error: boolean
  isHidden: boolean

  constructor(slot: any, pending: boolean = false, error: boolean = false) {
    makeAutoObservable(this)
    this.slot = slot
    this.pending = pending
    this.error = error
    this.isHidden = false
  }

  get ready() {
    return this.slot.state === 2
  }

  get preparing() {
    return this.slot.state === 1
  }
}

export interface Group {
  id: string
  name: string
  color: string
  sequenceId: boolean
  [x: string]: any
}

const buildGroupsStore = (data: Group[]) => {
  const store: { [x: string]: Group } = {}
  data.forEach((group) => {
    store[group.id] = group
  })
  return store
}

export default Manage
