import React, { useContext, FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AppStoreCtx } from 'components'

// import debug from 'debug'
// const log = debug('@ikew:components:AuthenticatedApp')

export const AuthenticatedApp: FC<
  {
    component: any;
  } & RouteProps
> = ({ component: Component, ...rest }) => {
  const { storeId } = useContext(AppStoreCtx)
  
  return (
    <Route
      {...rest}
      render={(props: RouteProps) =>
        !!storeId ? <Component {...props} /> : <Redirect push to="/set-store" />
      }
    />
  );
};
