import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import { QueryParamProvider } from 'use-query-params'
import { PusherProvider } from '@harelpls/use-pusher'
import { SnackbarProvider } from 'notistack'
import { configure } from 'mobx'

import { theme } from './theme'
import { AppStoreProvider, HubProvider, AuthenticatedApp } from 'components'
import SetStore from './views/SetStore'
import ManageApp from './views/Manage'
import Error from './views/Error404'
import pusherConfig from './pusherConfig'
import { FirestoreProvider } from './hooks'

// import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/scss/main.scss'

configure({
  enforceActions: 'never',
})

function App() {
  return (
    <FirestoreProvider>
      <AppStoreProvider>
        <HubProvider>
          <ThemeProvider theme={theme}>
            <Router>
              <PusherProvider {...pusherConfig.client}>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <SnackbarProvider maxSnack={3}>
                    <Switch>
                      <Route exact component={SetStore} path="/set-store" />
                      <AuthenticatedApp exact component={ManageApp} path="/" />
                      <Route component={Error} /> 
                    </Switch>
                  </SnackbarProvider>
                </QueryParamProvider>
              </PusherProvider>
            </Router>
          </ThemeProvider>
        </HubProvider>
      </AppStoreProvider>
    </FirestoreProvider>
  )
}

export default App
