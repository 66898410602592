import { useMemo } from 'react'
import firebase from 'firebase'
import {} from 'firebase/firestore'

import React, { ReactNode, createContext, useContext } from 'react'

import { STORE_ID } from '../types/localStorageAliases'

// import debug from 'debug'
// const log = debug('@kds')

interface FirestoreContextType {
  [key: string]: any
}

export const FirestoreContext = createContext<FirestoreContextType>({} as FirestoreContextType)

export const FirestoreProvider = ({ children }: { children: ReactNode }) => {
  const collectionName = localStorage.getItem(STORE_ID)
  const db = useMemo(() => {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: 'ikew-e1fb1.appspot.com',
      messagingSenderId: '346100395305',
      appId: '1:346100395305:web:b5e6551f5c276dfc3e1b38',
    }
    firebase.initializeApp(firebaseConfig)
    return firebase.firestore()
  }, [])

  return (
    <FirestoreContext.Provider value={{ db, collectionName }}>{children}</FirestoreContext.Provider>
  )
}

export const useFirestore = () => {
  const props = useContext(FirestoreContext)
  return { ...props }
}
