import localStorage from 'store2'

const client: any = {
  clientKey: process.env.REACT_APP_PUSHER_KEY,
  cluster: 'ap1',
  authEndpoint: `${process.env.REACT_APP_HUB_URI}/pusher/auth`,
  auth: {
    params: {
      store: localStorage.get('storeId'),
      mode: 'manage',
    },
    headers: {},
  },
}

const pusherConfig = {
  client,
}

export default pusherConfig
