import { useContext, useMemo } from 'react'
import { AppStoreCtx } from 'components'
import { ManageService } from 'service'
import { useFirestore } from 'hooks'

export const useManageService = () => {
  const { db } = useFirestore()
  const { storeId }: any = useContext(AppStoreCtx)
  const service = useMemo(() => new ManageService(db, storeId), [db, storeId])
  return service
}

