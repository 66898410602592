import { getUnixTime } from 'date-fns'
import { ISlot } from 'AppDB'
import { SLOT_TYPES } from '../types/SlotTypes'

import debug from 'debug'
const log = debug('@ikew:ManageService')

export class ManageService {
  db: any = null
  hub: string
  store: string

  constructor(db: any, storeId: string) {
    this.db = db
    this.store = storeId
    this.hub = process.env.REACT_APP_HUB_URI || ''
  }

  activeSlots(): Promise<ISlot[]> {
    const { db, store } = this
    return new Promise((resolve, reject) => {
      const slots: ISlot[] = []
      db.collection(store)
        .where('state', '!=', SLOT_TYPES.CLEARED)
        .get()
        .then((snapshot: any) => {
          snapshot.forEach((doc: any) => {
            slots.push({
              id: doc.id,
              ...doc.data(),
            })
          })
          resolve(slots)
        })
        .catch((error: Error) => {
          reject(error)
        })
    })
  }

  addInProgress(slot: IAddInProgress): Promise<ISlot> {
    return new Promise((resolve, reject) => {
      const { db, store } = this
      const inProgress = {
        ...slot,
        state: SLOT_TYPES.IN_PROGRESS,
        created: getUnixTime(new Date()),
      }
      db.collection(store)
        .add(inProgress)
        .then(async (docRef: any) => {
          const persistedSlot = await docRef.get('cache')
          resolve({
            id: persistedSlot.id,
            ...persistedSlot.data(),
          })
        })
        .catch((err: Error) => {
          reject(err)
        })
    })
  }

  inProgressToReady(slot: ISlot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const { db, store } = this
      const now = getUnixTime(new Date())
      var ref = db.collection(store).doc(slot.id)
      ref
        .update({
          state: 2,
          readyDate: now,
        })
        .then(() => {
          resolve(true)
        })
        .catch((error: Error) => {
          reject(error)
        })
    })
  }

  clear(slot: ISlot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const { db, store } = this
      var ref = db.collection(store).doc(slot.id)
      ref
        .delete()
        .then(() => {
          resolve(true)
        })
        .catch((error: Error) => {
          reject(error)
        })
    })
  }

  clearReady(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const { db, store } = this
      const batch = db.batch()
      db.collection(store)
        .where('state', '==', 2)
        .get()
        .then(async (snapshot: any) => {
          if (snapshot.length === 0) {
            resolve(true)
            return
          }

          snapshot.forEach((doc: any) => {
            batch.delete(doc.ref)
          })
          try {
            await batch.commit()
            resolve(true)
          } catch (error) {
            reject(false)
          }
        })
        .catch((err: Error) => {
          log(err)
          reject(false)
        })
    })
  }

  removePreparing(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const { db, store } = this
      const batch = db.batch()
      db.collection(store)
        .where('state', '==', 1)
        .get()
        .then(async (snapshot: any) => {
          if (snapshot.length === 0) {
            resolve(true)
            return
          }

          snapshot.forEach((doc: any) => {
            batch.delete(doc.ref)
          })
          try {
            await batch.commit()
            resolve(true)
          } catch (error) {
            reject(false)
          }
        })
        .catch((err: Error) => reject(err))
    })
  }

  bumpReady(slot: ISlot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const { db, store } = this
      const now = getUnixTime(new Date())
      var ref = db.collection(store).doc(slot.id)
      ref
        .update({
          readyDate: now,
        })
        .then(() => {
          resolve(true)
        })
        .catch((error: Error) => {
          reject(error)
        })
    })
  }

  setWaitTime(waitTime: number, expire: number): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const { db, store } = this
      var ref = db.collection(store).doc('_state')
      ref
        .set(
          {
            waitTime,
            expire,
          },
          { merge: true },
        )
        .then(() => {
          resolve(true)
        })
        .catch((error: Error) => {
          reject(error)
        })
    })
  }

  resetWaitTime(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const { db, store } = this
      var ref = db.collection(store).doc('_state')
      ref
        .set(
          {
            waitTime: null,
            expire: null,
          },
          { merge: true },
        )
        .then(() => {
          resolve(true)
        })
        .catch((error: Error) => {
          reject(error)
        })
    })
  }
}

interface IAddInProgress {
  group: string
  label: string
}
