import React, { useState } from 'react'
import localStorage from 'store2'

// import debug from 'debug'
// const log = debug('@ikew:components:AppStore')

const AppStoreCtx: any = React.createContext(null)

const idKey = 'storeId'

export const AppStoreProvider = (props: any) => {
  const { children } = props
  const [storeId, setStoreId] = useState<string | null>(null)

  // migrate old key
  localStorage.transact(idKey, (curr: any) => {
    const oldKey = 'deliverdis-store'
    if (localStorage.has(oldKey)) {
      curr = localStorage.get(oldKey)
      return curr
    }
  })

  const sid = localStorage.get(idKey)
  const saveStoreId = (storeId: string) => {
    setStoreId(storeId)
    localStorage.set(idKey, storeId, true)
  }

  const value = { storeId: storeId || sid, saveStoreId }

  return <AppStoreCtx.Provider value={value}>{children}</AppStoreCtx.Provider>
}

export default AppStoreCtx
