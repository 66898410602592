import React, { useContext, useMemo } from 'react'

import HubService from 'service/hub'
import { AppStoreCtx } from 'components'

// import debug from 'debug'
// const log = debug('@ikew:components:Hub')

export const HubContext: any = React.createContext(null)

export const HubProvider = (props: any) => {
  const { children } = props
  const { storeId }: any = useContext(AppStoreCtx)
  const service: any = useMemo(() => new HubService(storeId), [storeId])
  return <HubContext.Provider value={service}>{children}</HubContext.Provider>
}

export const useHub = () => {
  const service: HubService = useContext(HubContext)
  return service
}
